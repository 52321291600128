import { BrowserRouter, Routes, Route, HashRouter, Navigate } from "react-router-dom";
import Home from "../src/Components/Pages/Home"
import Layout from "../src/Components/Layout"
import Contact from "./Components/Pages/Contact";
import About from "./Components/Pages/About";
import References from "./Components/Pages/References";
import Services from "./Components/Pages/Services";
import ScrollToTop from "./Components/utils/ScrollToTop"
import GlobalStyle from "./Styles/global";
function App() {
  return (
    <>
  <BrowserRouter>
  <GlobalStyle></GlobalStyle>
    <ScrollToTop></ScrollToTop>
 
        <Routes>
          <Route path="/" element={<Layout />}>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/about" element={<About />}></Route>
            <Route index element={<Home />}></Route>
            <Route path="/contact" element={<Contact/>}></Route>
            <Route path="/references" element={<References />}></Route>
        <Route path="/*" element={<Navigate to="/"/> } ></Route>
          </Route>
       
        </Routes>
        
      </BrowserRouter>
    </>
  );
}

export default App;
