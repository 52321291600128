import { useRef } from "react"
import styled from "styled-components"
import emailjs from "emailjs-com"
import { useState } from "react"
import { useEffect } from "react"

const ContactContainer = styled.section`
    background: #1F1F1F;
    display: flex;
justify-content: center;

`

const ContactForm = styled.form`
width: 100%;
display: flex;
flex-direction: column;
text-align: center;
padding: 1.5em;

    @media(min-width: 768px) {
        width: 75%;
    }
    @media(min-width: 1000px) {
        width: 50%;
    }

`

const Input = styled.input`
margin-bottom: 1rem;
padding: 0.5rem;
width: 100%;
height: 2.5em;
border: white 1px solid;
border-radius: 0.5rem;
::placeholder {
    color: black;
}

`

const MessageInput = styled.textarea`
    margin-bottom: 1rem;
    height: 10em;
    padding: 0.5rem;
    border: white 1px solid;
    border-radius: 0.5rem;
background: light-grey;

::placeholder {
    color: black;
}
`


const ContactButton = styled.button`

width: 6.5rem;
border 1px solid white;
padding: 0.5em;

color: white;
border: none;
border-radius: 1.5rem;
background-color: ${({ disabled }) => disabled ? 'grey' : '#b43f3f'};
cursor: ${({ disabled }) => disabled ? 'inital' : 'pointer'};



`

const RightSideContainer = styled.div`
text-align: right;
`


const Contact = () => {
    const form = useRef();
    const [colorButton, setColorButton] = useState(false)    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const validate = () => {
        return name, email, phone, message
      
        
    }


    
   
    const {REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_PUBLIC_KEY} = process.env


   

    const service = process.env.REACT_APP_SERVICE_ID
    const template = process.env.REACT_APP_TEMPLATE_ID
    const publickey = process.env.REACT_APP_PUBLIC_KEY

    const sendEmail = (e) => {
        e.preventDefault();
       
        emailjs.sendForm(service, template, form.current, publickey)
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };

    return(
       <ContactContainer>
        <ContactForm ref={form} onSubmit={sendEmail}>
          <h2 style={{color: "white", marginBottom: "1rem"}}>Ta Kontakt med oss</h2>
          <Input placeholder="Namn" name="name" required onChange={(e) => setName(e.target.value)}></Input>
          <Input  required placeholder="Email" name="email" type="email" onChange={(e) => setEmail(e.target.value)} ></Input>
          <Input required placeholder="Telefon" type="number" name="phone" onChange={(e) => setPhone(e.target.value)} ></Input>
          <MessageInput required placeholder="Meddelande" name="message" onChange={(e) => setMessage(e.target.value)}></MessageInput>
          <RightSideContainer>
         <ContactButton type="submit" disabled={!validate()}>Kontakta oss</ContactButton>
          </RightSideContainer>
        </ContactForm>
       </ContactContainer>
    )
}


export default Contact