


import styled from "styled-components"
import { Link } from "react-router-dom";
import img from "../Images/Logo.png"
import Burger from "./Burger";
const Container = styled.nav`
padding-top: 1rem;
padding-bottom: 1rem;
position: fixed;
width: 100%;
background-color: rgba(0,0,0,0.6);
list-style-type: none;
display: flex;
justify-content: space-between;
align-items: center;
z-index: 1000
`






const imgStyle = {
    height: "3.5rem",
    marginLeft: "2rem",
    margin: "0",
  };

export const Navbar = () => {
    return(
        <>
        
       <Container>
       <Link to="/"><img src={img} alt="Brand Logo" style={imgStyle} /></Link>
       <Burger/>
       
       </Container>
        </>
    )
}