import Card from "../Card"

import styled from "styled-components"


const LinkStyle = {
        color: "white",
        cursor: "pointer",
        textDecoration: "none",
        padding: "1em",
        fontSize: "1.2rem",
        textAlign: "center"
      };

const Container = styled.div`
padding: 5em;
display: grid;
grid-template-columns: repeat( auto-fit, minmax(350px, 1fr) );
grid-gap: 20px;
width: 100%;
@media (max-width: 500px) {
    justify-content: center
   
   }
`

const Header = styled.header`
padding-top: 7rem;
text-align: center;
color: white;
font-family: montserrat;
`

const HeaderText = styled.h1`

`

const HeaderParagraph = styled.h2`
    margin-bottom: 1rem;
`

const GoogleReviews = styled.button`
border:none;
border-radius: 1rem;
padding: 0.5em;
background-color: red;
color: white;

`

const References = () => {
    return(
        <>
        <Header>
            <HeaderText>Referenser</HeaderText>
            <HeaderParagraph>Kolla över vad våra tidigare kunder sagt</HeaderParagraph>
            <GoogleReviews><a style={LinkStyle} href="https://www.google.com/search?q=m+elteknik+&sxsrf=ALiCzsZA6QyBF_NBAEQBsmEsrpzZI8Xo1w%3A1660945483557&source=hp&ei=SwQAY5f9H7H3qwHU_4-4CQ&iflsig=AJiK0e8AAAAAYwASWxeJR6pkhfgVWrXeYhgydz1-G8HN&ved=0ahUKEwjXzqWM8NP5AhWx-yoKHdT_A5cQ4dUDCAY&uact=5&oq=m+elteknik+&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyBAgjECcyCgguEMcBEK8BECcyBggAEB4QFjIGCAAQHhAWMgYIABAeEBYyBggAEB4QFjICCCY6BAgAEEM6BQgAEIAEOgsILhCABBDHARDRAzoICC4QgAQQ1AI6BAguEEM6DQguEMcBENEDENQCEEM6CwguEIAEEMcBEK8BOgcIABCABBAKOgoILhDHARDRAxAKOgQILhAKOgQIABAKOgoILhDHARCvARAKOggIABAeEA8QFjoICAAQHhAWEAo6BAgAEA06CgguEMcBEK8BEA06BwguENQCEA06BggAEB4QDVAAWLYIYP4IaABwAHgAgAF8iAGCB5IBAzguMpgBAKABAQ&sclient=gws-wiz#lrd=0x465de9de1ae7e2a3:0x3dcc2f1455d6babb,1,,,">Se alla våra referenser</a></GoogleReviews>
        </Header>
        <Container>
        <Card name="Janne C" desc="Jag kan ge dessa killar de allra bästa rekommendationer. Grymt snabbt, noggrant och väl utfört arbete.
Dessutom är dom sjukt glada och trevliga!

Fakturan motsvarade det cirkapris jag fick, och det råder ingen tvekan om  vem jag skall ringa till när jag behöver få elarbete utfört nästa gång.

Så här jobbar riktiga proffs!"/>
        <Card name="Siri S" desc="Har anlitat Marek vid två tillfällen och varje gång har han och lärlingen levererat det allra bästa . Kan varmt rekommendera M Elteknik"/>
        <Card name="Omar A" desc="Hade denna elektriker i på vår renovering av sommarstugan utanför Smedjebacken.
Bra på alla sätt, noggrann och återkopplingen var toppen!
Rekommenderas"/>
        <Card name="Ulrika H" desc="Kommer absolut att anlita dem igen! Jobbet bra gjort och bra bemötande. Väldigt nöjd!"/>
        <Card name="Mika P" desc="Snabbt, smidigt och proffsigt! Mycket nöjd."/>
        <Card name="Kerstin L" desc="Snabb och proffsig hjälp. Vänligt bemötande. Mycket nöjd med reparation och utbyte av transformator till spots. Rekommenderas på det varmaste."/>
        <Card name="Andreas E" desc="Jättebra bemötande och ett väl utfört arbete.
Städade till och med efter sig (något jag aldrig sett en elektriker göra tidigare)."/>
        <Card name="Anna A" desc="Jag kan verkligen rekommendera M-elteknik.
Väldigt kunniga och noggranna!
Dessutom snabba och effektiva!
Supernöjd! 👍🏼"/>
        <Card name="Brf Sparrhornet 2" desc="Fullt ut nöjd med M:eltekniks tjänster!
En kundorienterat företag!"/>
        
        </Container>
        </>
    )
}


export default References
