import styled from "styled-components"
import mel from "../../Images/företag.jpg"
import marek from "../../Images/marek.jpg"
import markus from "../../Images/Markus.jpg"
import adam from "../../Images/adham.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faHandshake} from '@fortawesome/free-solid-svg-icons'

import { faHardHat} from '@fortawesome/free-solid-svg-icons'

import { faTachometer} from '@fortawesome/free-solid-svg-icons'


const FrontPageImage = styled.img`

  height: 400px;
width: 100%;
    object-fit cover;
    object-position: 75% 50%;
    @media(min-width: 1200px) {
      height: 500px;
     
    }

`

const Header = styled.header`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
margin-bottom: 4rem;
`

const HeaderText = styled.h1`
text-align: center;
border-bottom: 1px solid white;
color: white;
margin-top: 3rem;
margin-bottom: 1rem;
`
const HeaderParagraph = styled.p`
color: white;
font-size: 1.5rem;
align-items: center;
width: 100%;
padding: 1em;
text-align: center;


`

const SecondHeading = styled.section`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
`

const SecondHeaderText = styled.h2`

border-bottom: 1px solid white;
color: white;
font-size: 2rem;
text-align: center;
margin-bottom: 1rem;
`
const SecondHeaderParagraph = styled.p`
padding: 1em;
color: white;
font-size: 1.5rem;
align-items: center;
width: 100%;
text-align: center;
margin-bottom: 4rem;
`
const ServicesFigure = styled.figure`
    color: white;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 2rem;
    padding: 2rem;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
`

const ServicesCaption = styled.figcaption`
  font-size: 2rem;
`

const IconContainer = styled.div`
    width: 100%;

`

const TeamContainer = styled.section`
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;


`

const EmployeePicture = styled.img`
width: 20rem;
object-fit: cover;
margin-right: 1rem;
`
const EmployeesContainer = styled.figure`


`
const EmployeeDescription = styled.figcaption`
color: white;
text-align: center;
margin-top: 1rem;
margin-bottom: 1rem;

`

const About = () => {
    return(
        <>
        <FrontPageImage src={mel}></FrontPageImage>
        <Header>
          <HeaderText>Om M-Elteknik</HeaderText>
          <HeaderParagraph>M-Elteknik i Sverige AB är en elfirma från Fagersta som funnits på plats sedan 2017 men har arbetat inom branschen i cirka 19 år. Vi har kunniga elektriker med kunskaper i allt från industri till heminstallation. Oavsett vad för jobb som ska görs värderar vi alltid att du som kund ska bli nöjd med det arbetet som utförts. Du kan lita på M-Elteknik, Slå en signal och få en utvärdering nu oavsett vad för typ av jobb du behöver ha utfört!</HeaderParagraph>
        </Header>
        <SecondHeading>
        <SecondHeaderText>Kund Värdering</SecondHeaderText>
        <SecondHeaderParagraph>Vi på M-Elteknik värderar samarbetet och kommunikationen med vår kund över allt för att tillsammans nå bästa möjliga resultat du som kund förväntar dig utav vårat arbete! </SecondHeaderParagraph>
        <SecondHeaderText>Varför M-Elteknik?</SecondHeaderText>
        </SecondHeading>
        <ServicesFigure>
        <IconContainer>
        <FontAwesomeIcon style={{textAlign: "center", fontSize: "5rem", color: "#FFFCC0"}}icon={faMoneyBill}/>
        <ServicesCaption>Rimliga priser</ServicesCaption>
        </IconContainer>
        <IconContainer>
        <FontAwesomeIcon style={{textAlign: "center", fontSize: "5rem", color: "#FFFCC0"}}icon={faHandshake}/>
        <ServicesCaption>Tydlig kommunikation</ServicesCaption>
        </IconContainer>
        <IconContainer>
        <FontAwesomeIcon style={{textAlign: "center", fontSize: "5rem", color: "#FFFCC0"}}icon={faHardHat}/>
        <ServicesCaption>Certifierade och kunniga elektriker</ServicesCaption>
        </IconContainer>
        <IconContainer>
        <FontAwesomeIcon style={{textAlign: "center", fontSize: "5rem", color: "#FFFCC0"}}icon={faTachometer}/>
        <ServicesCaption>Snabbt utfört arbete</ServicesCaption>
        </IconContainer>
        
        </ServicesFigure>
        
        <SecondHeaderText>Vårat Team</SecondHeaderText>
        <TeamContainer>
      
        <EmployeesContainer>
          <EmployeePicture alt="Picture of employee" src={marek}></EmployeePicture>
          <EmployeeDescription>Marek Chojnacki Chef och Elektriker</EmployeeDescription>
          </EmployeesContainer>
          <EmployeesContainer>
          <EmployeePicture alt="Picture of employee" src={markus}></EmployeePicture>
          <EmployeeDescription>Marcus Wahlgren Elektriker</EmployeeDescription>
          </EmployeesContainer>
          <EmployeesContainer>
          <EmployeePicture alt="Picture of employee" src={adam}></EmployeePicture>
          <EmployeeDescription>Adam Apanowicz Elektriker</EmployeeDescription>
          </EmployeesContainer>
          
          
          
         
        </TeamContainer>
        <h1 style={{textAlign: 'center', color: "white"}}>Vi söker folk</h1>
        <p style={{textAlign: 'center', color: "white", marginBottom: "1rem", padding: "1em"}}>Vill du jobba som elektriker med ett positivt och glatt team? Är du elektriker som letar ny arbetsplats? Tveka inte! Kontakta oss och skicka en intresseanmälan! </p>
        </>
    )
}


export default About