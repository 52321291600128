
import { Outlet } from "react-router-dom";
import { Navbar } from "./Navbar";
import Footer from "./Footer";

const Layout = () => {
    return(
        <>
        <Navbar>Navbar goes here</Navbar>
   
        <Outlet/>
        <Footer/>
        </>
    )
}


export default Layout