import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';

const sliderStyle = {
  maxHeight: "100%",
  textAlign: "center",
  display: "flex", 
  flexDirection: "column",
  justifyContent: "center",

};

const Text = styled.p`
color: white;
font-size: 1.5rem;
`

const ReferenceContainer = styled.div`
display: flex;
flex-direction: column;
  color: white;
  padding-left: 5em;
  padding-right: 5em;
  padding-top: 2em;
  padding-bottom: 2em;
 
`

const ReferenceSwiper = () => {
    return(
        <Swiper
      spaceBetween={50}
      slidesPerView={1}
      modules={[Navigation, Pagination]}
      navigation
      loop={true}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      style={{display: "flex", justifyContent: "center", alignItems: "center", zIndex: "1"}}
    >
      <SwiperSlide style={sliderStyle}> 
      <ReferenceContainer>
      <FontAwesomeIcon style={{textAlign: "center", fontSize: "3rem"}}icon={faQuoteRight}/>
      <Text>Mycket nöjd med utfört arbete. Lätta att ha att göra med, snabba och levererar efter överenskommelse.</Text>
      </ReferenceContainer>
      </SwiperSlide>
      <SwiperSlide style={sliderStyle}> 
      <ReferenceContainer>
      <FontAwesomeIcon style={{textAlign: "center", fontSize: "3rem"}}icon={faQuoteRight}/>
        <Text>Mycket trevliga och lösningsfokuserade. Arbetet blev bra och jag är nöjd. Kunden i fokus.</Text>
        </ReferenceContainer></SwiperSlide>
      <SwiperSlide style={sliderStyle}>
        <ReferenceContainer>
      <FontAwesomeIcon style={{textAlign: "center", fontSize: "3rem"}}icon={faQuoteRight}/>
     
        <Text>Jag kan ge dessa killar de allra bästa rekommendationer. Grymt snabbt, noggrant och väl utfört arbete. </Text></ReferenceContainer>
</SwiperSlide>
      
      
    </Swiper>
    )
}

export default ReferenceSwiper