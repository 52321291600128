import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'


const CardContainer = styled.div`
    
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    border-top: 1px solid
    display: flex;
    flex-direction: column;
    padding: 1em;
    text-align: center;
    justify-content: center;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   
`

const IconStyling = {
    fontSize: "5rem",
    color: "lightgrey",
    marginBottom: "1rem"
}



const ReferenceName = styled.h2`
text-align: center;

`


const ReferenceParagraph = styled.p`
    font-size: 1.2rem;
    padding: 1em;
    font-weight: lighter;
  
`
const Card = (props) => {
    return(
       <>
       <CardContainer>
       <FontAwesomeIcon style={IconStyling}icon={faUser}/>
        <ReferenceName>{props.name}</ReferenceName>
        <ReferenceParagraph>{props.desc}</ReferenceParagraph>
       </CardContainer>
       </>
    )
}



export default Card