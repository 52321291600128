
import ServicesPic from "../../Images/Service.png"
import styled from "styled-components"
import cables from "../../Images/cables2.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndustry } from '@fortawesome/free-solid-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faBuilding} from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-scroll"

import ReferenceSwiper from "../ReferencesSwiper"
import Contact from "../Contact"




 

  const FrontPageHeaderText = styled.h1`
  font-size: 3rem;
  font-family: 'Montserrat', sans-serif;

  `
  const FrontPageParagraph = styled.p`
 
  padding: 0;
  margin-top: 0.5rem;;
  line-height: 1;

  color: white;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  `

  const FrontPageText = styled.figcaption`
  padding: 2em;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  `


  const ContactButton = styled.button`
  background-color: red;
  padding: 0.5em;
  margin-top: 1rem;
  color: white;
  border-radius: 1.5rem;
 
  font-size: 1.2rem;

  font-family: montserrat;
  border: none;
  `
  const NumberContactContainer = styled.div`

  background: #FFFF4A;

  width: 100%;
  height: 75px;

  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  `


  const EstimationText = styled.h2`
    text-align: center;
    font-size: 2rem;
    font-weight: lighter;
  `

  
const WelcomeContainer = styled.div`
display: flex;
justify-content: center;
text-align: center;
color: white;
max-height: 100%;
width: 100%;
padding-bottom: 1rem;
background-size: cover;
background-position: center;
background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${cables})

`



const WelcomeText = styled.div`
padding: 5rem;
text-align: center;
width: 100%;





`

const Melteknik = styled.p`
  
text-align: center;
  
`

const BorderBottomText = styled.p`
border-bottom: 1px solid white;
padding: 1em;
text-align: center;
`

const ServicesContainer = styled.div`

display: flex;
justify-content: space-evenly;
gap: 100px;
  @media (max-width: 1000px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    gap: 20px;
  }
`

const IndividualService = styled.div`
display: flex;
flex-direction: column;
text-align: center;
justify-content: center;
align-items: center;
width: 100%;
`

const Figure = styled.figure`


display: flex;
justify-content: center;
text-align: center;
color: white;
height: 100vh;
width: 100vw;
background-size: cover;
background-position: center;
background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${ServicesPic})







`
const RandomDiv = styled.div`
margin-top: 1rem;
width: 25%;
border: 1px solid white;
`
const Home = () => {
    return(
      <>
      <Figure>
      
    
      <FrontPageText>
      <FrontPageHeaderText>Endast Snabb Professionell Service.</FrontPageHeaderText>
      <FrontPageParagraph>Våra Elektriker är här för att hjälpa dig</FrontPageParagraph>
      <RandomDiv></RandomDiv>

  <ContactButton><Link activeClass="active" className="test1" to="test1" spy={true} smooth={true} duration={1000} offset={2500}>Kontakta oss</Link></ContactButton>
      
      </FrontPageText>
      </Figure>
      <NumberContactContainer>
     
        <EstimationText>
          <p style={{textAlign: "center"}}>Få en estimering nu</p>
         <FontAwesomeIcon icon={faPhone}/> 076-8424666
        </EstimationText>
       
      
      </NumberContactContainer>

      <WelcomeContainer>
        
        <WelcomeText><h1>Välkommen till oss på M-Elteknik</h1>
        <Melteknik>M-Elteknik är ett företag i Fagersta med omnejd- Viktigaste för oss är att göra dig som kund nöjd</Melteknik>
      
        <BorderBottomText>Vi har elektriker med lång erfarenhet och med alla de behörigheter branschen kräver.
M-Elteknik AB utför alla typer av elarbeten både för privatpersoner & företag samt föreningar.
Exempelvis el-installationer,underhåll, felsökning, datanätverk, Installation av vitvaror,dragning av ny el m.m.</BorderBottomText>
<h1 style={{marginBottom: "2rem", marginTop: "2rem"}}>Vad vi gör på M-Elteknik</h1>
<ServicesContainer>
      
        <IndividualService>
         
          <FontAwesomeIcon style={{textAlign: "center", fontSize: "4rem"}}icon={faIndustry}/>
          <h1>Företag</h1>
          <p style={{width: "100%"}}>Vi gör jobb hos företag exempelvis elinstallationer, felsökning, service, felsökning, akuta utryckningar, jour och konsultation. Självklart har vi de behörigheter som krävs. </p>
        </IndividualService>
        <IndividualService>
          <FontAwesomeIcon style={{textAlign: "center", fontSize: "4rem"}}icon={faHouse}/>
          <h1 style={{textAlign: "center"}}>Privatpersoner</h1>
          <p>

Vi gör allt från större elinstallationer till mindre jobb som att exempelvis installera ett eluttag.
Självklart har vi de behörigheter som krävs för att garantera att det jobb vi gör ska bli säkra och rätt utförda.

Jobb med ROT-avdrag
Privatpersoner kan få skatterabatt på arbetskostnaden vid elservice i hemmet.
</p>
        </IndividualService>
        <IndividualService>
        <FontAwesomeIcon style={{textAlign: "center", fontSize: "5rem"}}icon={faBuilding }/>
        <h1>Föreningar</h1>
        <p>Vi jobbar med föreningar där allt kan handla om att byta elcentral, dra kabel eller installera ett eluttag. Vi har även jour och är snabbt på plats för att hjälpa dig när det behövs. Vi har det behörigheter som krävs för att utföra ett professionellt och säkert el-jobb.</p>
        </IndividualService>
        </ServicesContainer>
        </WelcomeText>
   
      </WelcomeContainer>

      <ReferenceSwiper/>
      
      <Contact  />
     
      </>
    )
}

export default Home