import styled from "styled-components"
import { useRef } from "react"
import emailjs from "emailjs-com"
import { useState } from "react"



const ParagraphStyling = {
    color: "white"
}

const Form = styled.form`
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;
    @media (max-width: 670px) {
        width: 90%;
        
       }
   
    
`

const Input = styled.input`
padding: 0.5rem;
margin-bottom: 1.5rem;
height: 2.5em;
background: white;
border: none;
border-radius: 0.5rem;
width: 100%;
@media (min-width: 1400px) {
    width: 75%;
   }
   
`

const MessageInput = styled.input`
padding: 0.5rem;
margin-bottom: 1.5rem;
height: 8em;
background: white;
border: none;
border-radius: 0.5rem;
width: 100%;
@media (min-width: 1400px) {
    width: 75%;
   }
`

const MessageButton = styled.button`
@media (min-width: 1400px) {
    width: 75%;
   }
   margin-bottom: 1rem;
width: 100%;
color: white;
height: 2.5rem;
font-size: 1rem;
border: none;
border-radius: 0.5rem;
background-color: ${({ disabled }) => disabled ? 'grey' : '#b43f3f'};
cursor: ${({ disabled }) => disabled ? 'inital' : 'pointer'};

`

const ContactUsText = styled.h1`
color: white;
margin-bottom: 0;
`

const MapsContainer = styled.aside`
    width: 50%;
    border: none;s
    background-color: rgba(0,0,0,.75);
       margin: 0;
       @media (max-width: 670px) {
        width: 100%;
        height: 30rem;
       }
`

const ContactMainContainer = styled.main`
padding-top: 5rem;
display: flex;
justify-content: center;
width: 100%;
height: 100vh;
@media (max-width: 670px) {
    flex-direction: column;
    align-items: center;
   }


`




const Contact = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const validate = () => {
        return name, email, phone, message
    }

   

     const form = useRef();


    const service = process.env.REACT_APP_SERVICE_ID
    const template = process.env.REACT_APP_TEMPLATE_ID
    const publickey = process.env.REACT_APP_PUBLIC_KEY

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm(service, template, form.current, publickey)
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
          document.getElementById("button").disabled = true
      };
    

    return(
        <>
        <ContactMainContainer>
        
        <Form ref={form} onSubmit={sendEmail}>
            <ContactUsText>Kontakta oss</ContactUsText>
            <p style={{color: "white", marginBottom: "1rem"}}>Vi hjälper dig gärna!</p>
        <Input placeholder="Namn" name="name" onChange={(e) => setName(e.target.value)}/>
        <Input placeholder="Telefon" name="phone" onChange={(e) => setPhone(e.target.value)}/>
        <Input placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)}/>
        <MessageInput placeholder="Meddelande" name="message" onChange={(e) => setMessage(e.target.value)}/>
        <MessageButton id="button" type="submit" disabled={!validate()}>Skicka</MessageButton>
        <span style={{color: "white"}}>Ringer du hellre? Ring oss på 076- 8424666</span>
        </Form>
        
        <MapsContainer><iframe title="Google maps cordinate for m-elteknik company" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7984.7214711619035!2d15.7978354!3d59.9789185!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3dcc2f1455d6babb!2sM%20Elteknik!5e0!3m2!1sen!2sse!4v1660263049637!5m2!1sen!2sse" width="100%" height="100%" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></MapsContainer>
        </ContactMainContainer>
        </>
    )
}

export default Contact