import { Link } from "react-router-dom"
import styled from "styled-components"

const LinkMenu = styled.ul`
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    li {
        padding: 18px 10px;
    }


    @media (max-width: 768px) {
        text-align: center;
        flex-flow: column nowrap;
        background-color: #0D2538;
        z-index: 1000;
        position: fixed;
        transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
        top: 0;
        right: 0;
        width: 100%;
        transition: transform 0.3s ease-in-out;
        li {
          color: #fff;
        }
      }

`


const LinkStyle = {
    color: "white",
    cursor: "pointer",
    textDecoration: "none",
    marginRight: "2rem",
    fontSize: "1.2rem",
    
  };


  
 
  

  
const RightNav = (props) => {
  const {open, setOpen} = props
    return(
        <LinkMenu open={open}>
        <li><Link style={LinkStyle} onClick={() => setOpen(!open)} to="/references">Referenser</Link></li>
        <li> <Link style={LinkStyle} onClick={() => setOpen(!open)} to="/about">Om Företaget</Link></li>
        <li>  <Link style={LinkStyle} onClick={() => setOpen(!open)} to="/services">Våra Tjänster</Link></li>
        <li><Link style={LinkStyle}onClick={() => setOpen(!open)} to="/contact">Kontakta oss</Link>
</li>
        </LinkMenu>
    )
}


export default RightNav