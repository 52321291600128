import styled from "styled-components"

import ServicesPic from "../../Images/marek-background.jpg"
import Logo from "../../Images/Logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faWrench, faShippingFast, faEye, faBusinessTime, faNetworkWired, faPen, faPeopleArrows} from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";


const LinkStyle = {
    color: "white",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "1.2rem",
    textAlign: "center",
  };

const IconStyling = {
    fontSize: "3.5rem",
    color: "#FFFCC0",
    marginRight: "1rem",
    textALign: "center",
    width: "50px"
}

const ServicesFigure = styled.figure`
display: flex;
justify-content: center;
align-items: center;
color: white;
height: 500px;
width: 100%;
@media (min-width: 1200px) {
    background-position: 50% 25%;
    background-size: cover;
}
background-size: cover;
background-position: top center;
background-image: url(${ServicesPic})




`
const ServicesCaption = styled.figcaption`


color: white;
`


const ServicesHeader = styled.header`
   
    padding: 5em;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;


`

const LogoImg = styled.img`
max-width: 500px;
max-height: 200px;

`

const AboutServices = styled.div`

    text-align: center;

    @media (min-width: 1200px) {
        width: 30%;
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
        align-items: center;
        justify-content: center;
     }

`

const ServicesProvided = styled.div`
Display: grid; 
width: 100%;
grid-template-columns: repeat(auto-fill, minmax(350px, 400px));
Grid-template-rows: repeat(4, auto);
Grid-gap: 10px;
justify-content: center;
align-items: center;
margin-bottom: 6rem;



    @media (min-width: 1200px) {
       grid-template-columns: 30% 30%;
       margin-left: 6rem;
    }
`

const Div = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const Div2 = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: white;
    @media (max-width: 768px) {
        display: flex; 
        flex-direction: column;
        justify-content: center;
        align-items: center;
       }
`










const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Button = styled.button`
text-align: center;
margin-top: 1rem;
margin-bottom: 1rem;
width: 100%;
color: white;
height: 2.5rem;
font-size: 1rem;
border: none;
border-radius: 0.5rem;
background-color: #b43f3f

`

const Sick = styled.div`
    width: 75%;
    text-align: center;
    color: white;
    @media(min-width: 1200px) {
        width: 30%;
    }

    @media(max-width: 400px) {
        width: 100%;
        padding: 1em;
    }

`







const Services = () => {
    return(
        <>
          
        <ServicesFigure>
            <ServicesCaption><h1 style={{fontWeight: "lighter"}}>Tjänster</h1></ServicesCaption>
      
        </ServicesFigure>
    
        <ServicesHeader>
            <LogoImg alt="Logo" src={Logo}/>
      
            <AboutServices>
                <h1 style={{color: "white"}}>Om våra tjänster</h1>
                <p style={{color: "white", textAlign: "center"}}>Vi på M-elteknik AB har ett brett tjänsteutbud inom elinstallation och service. Vi tar oss arbetar med det allra mesta du kan tänka dig inom elinstallation, där inget uppdrag är för stort eller för litet Vi åtar oss det mesta inom elinstallation, inga uppdrag är för smått eller för stort, hör av dig nu så kikar vi på precis vad inom elinstallation och service just du behöver hjälp med!</p>
            </AboutServices>
        </ServicesHeader>

        <ServicesProvided>
            <Div>
                <Div2>
                <FontAwesomeIcon style={IconStyling} icon={faEye}/>
        <h1>Underhåll</h1>
                </Div2>
            </Div>

            <Div>
                <Div2>
                <FontAwesomeIcon style={IconStyling} icon={faBolt}/>
        <h1>El-Installationer</h1>
                </Div2>
            </Div>
            
            <Div>
                <Div2>
                <FontAwesomeIcon style={IconStyling} icon={faWrench}/>
        <h1>Felsökning / Service </h1>
                </Div2>
            </Div>

            <Div>
                <Div2>
                <FontAwesomeIcon style={IconStyling} icon={faShippingFast}/>
        <h1>Akuta Utryckingar</h1>
                </Div2>
            </Div>


            <Div>
                <Div2>
                <FontAwesomeIcon style={IconStyling} icon={faPen}/>
        <h1>Dokumentation</h1>
                </Div2>
            </Div>

            <Div>
                <Div2>
                <FontAwesomeIcon style={IconStyling} icon={faNetworkWired}/>
        <h1>Nätverk / Data / Tele</h1>
                </Div2>
            </Div>


            <Div>
                <Div2>
                <FontAwesomeIcon style={IconStyling} icon={faPeopleArrows}/>
        <h1>Konsultering</h1>
                </Div2>
            </Div>


            <Div>
                <Div2>
                <FontAwesomeIcon style={IconStyling} icon={faBusinessTime}/>
        <h1>Jour</h1>
                </Div2>
            </Div>

            

            
        </ServicesProvided>
        
        <QuestionsContainer>
            <Sick>
            <h1>Frågor?</h1>
            <p style={{fontSize: '1.5rem'}}>Vi på M-Elteknik värderar vår kund kommunikation över allt för att nå bästa möjliga resultat tillsammans med dig som kund kommunikation
                Kontakta oss nu så besvarar vi alla dina frågor så fort som möjligt!
            </p>
            <Button><Link style={LinkStyle} to="/contact">Kontakta oss</Link></Button>
            </Sick>
        </QuestionsContainer>
        </>
    )
}

export default Services