import styled from "styled-components"

import img from "../Images/Logo.png"

import { FaGoogle, FaStar} from 'react-icons/fa';

const Container = styled.div`

position: relative;
padding: 1em;
width: 100vw;
background-color: rgba(0,0,0,0.8);
list-style-type: none;
display: flex;
justify-content: space-evenly;
align-items: center;
color: white;

@media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    
}


`
const LogoImg = styled.img`
   width: 10rem;
   margin-bottom: 1rem;
`

const LogoContainer = styled.div`
   text-align: center;
`

const SocialsContainer = styled.div`
    link-style-type: none;
    margin-bottom: 1rem;
    text-align: center;
`

const Ul = styled.ul`
list-style: none;
text-align: center;
`

const A = styled.a`
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.2rem;
`


  

const ReviewsContainer = styled.div`


`

const GoogleContainer = styled.div`
    font-size: 3rem;
    text-align: center;
`


const Footer = () => {
    return(
        <Container>
           
            <LogoContainer>    
            <p>M Elteknik I Sverige </p>      
            <LogoImg src={img}/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
           
            <p><span style={{fontWeight: "bold"}}>Adress:</span> Fårbovägen 7 73730 Fagersta</p>
        <p><span style={{fontWeight: "bold"}}>Email:</span> Marek@m-elteknik.se</p>
        <p ><span style={{fontWeight: "bold"}}>Tel:</span> 076-8424666</p>
        </div>
            </LogoContainer>  

            <SocialsContainer>
            <h1>Sociala medier</h1>
                <Ul>
               
                <li> <A href="https://sv-se.facebook.com/pages/category/Company/M-Elteknik-i-Sverige-AB-1409028052499706/">Facebook</A></li>
                <li> <A href="https://se.linkedin.com/in/marek-chojnacki-87350b19a?original_referer=https%3A%2F%2Fwww.google.com%2F">LinkedIn</A></li>
              
               
                </Ul>



            </SocialsContainer>


            <ReviewsContainer>
                <h1 style={{textAlign: "center"}}>Kolla in våra recensioner</h1>
                <A href="https://www.google.com/search?q=m+elteknik+&sxsrf=ALiCzsZA6QyBF_NBAEQBsmEsrpzZI8Xo1w%3A1660945483557&source=hp&ei=SwQAY5f9H7H3qwHU_4-4CQ&iflsig=AJiK0e8AAAAAYwASWxeJR6pkhfgVWrXeYhgydz1-G8HN&ved=0ahUKEwjXzqWM8NP5AhWx-yoKHdT_A5cQ4dUDCAY&uact=5&oq=m+elteknik+&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyBAgjECcyCgguEMcBEK8BECcyBggAEB4QFjIGCAAQHhAWMgYIABAeEBYyBggAEB4QFjICCCY6BAgAEEM6BQgAEIAEOgsILhCABBDHARDRAzoICC4QgAQQ1AI6BAguEEM6DQguEMcBENEDENQCEEM6CwguEIAEEMcBEK8BOgcIABCABBAKOgoILhDHARDRAxAKOgQILhAKOgQIABAKOgoILhDHARCvARAKOggIABAeEA8QFjoICAAQHhAWEAo6BAgAEA06CgguEMcBEK8BEA06BwguENQCEA06BggAEB4QDVAAWLYIYP4IaABwAHgAgAF8iAGCB5IBAzguMpgBAKABAQ&sclient=gws-wiz#lrd=0x465de9de1ae7e2a3:0x3dcc2f1455d6babb,1,,,">
                <GoogleContainer>
                <FaGoogle style={{marginRight: "1rem", textAlign: "center"}}/>
                <FaStar/>
                <FaStar/>
                <FaStar/>
                <FaStar/>
                <FaStar/>
                </GoogleContainer>
                </A>
            </ReviewsContainer>



        </Container>
    )
}


export default Footer